<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row>
      <v-col cols="6" md="6" sm="6" style="margin-bottom: 4px">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 700;
            font-size: 31px;
            line-height: 51px;
          "
        >
          หน้าหลัก
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="6" sm="6" class="text-right">
        <v-btn
          @click="submitAll('q_1_1')"
          color="#2AB3A3"
          dark
          style="padding: 12px 16px; gap: 8px; width: 158px; height: 48px"
          >บันทึก</v-btn
        >
      </v-col>
      <v-col cols="12" md="12" sm="12" class="mt-n3 mb-n4">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="mt-7 mb-4">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            color: #000000;
          "
        >
          Welcome Pop-up (ถ้ามี)
        </span>
      </v-col>
    </v-row>
    <v-card outlined class="pa-6">
      <v-row>
        <v-col cols="12" md="2" sm="2" xs="2">
          <span><b>Welcome pop-up</b></span>
        </v-col>
        <v-col cols="12" md="10" sm="10" xs="10">
          <v-img :src="showImage" max-width="200px" max-height="200px"> </v-img>
          <!-- <span>เลือกรูปภาพ Welcome Pop-up</span> -->
          <!-- <span style="color: red">*</span> -->
          <span
            >ขนาดรูปที่แนะนำ: 1500x1500
            <v-btn v-if="showImage" @click="deleteWelcomePopUp()" x-small
              >ลบ</v-btn
            >
          </span>
        </v-col>
        <v-col cols="12" md="2" sm="2" xs="2"> </v-col>
        <v-col cols="12" md="10" sm="10" xs="10" class="text-left">
          <input
            type="file"
            accept="image/png, image/jpeg"
            id="file"
            ref="q_1_1"
            v-on:change="submitfileMain('q_1_1')"
          />
        </v-col>
        <v-col cols="12" md="2" sm="2" xs="2">
          <span> ลิ้งดูรายละเอียดเพิ่มเติม (ถ้ามี) </span>
        </v-col>
        <v-col cols="12" md="5" sm="5" xs="5">
          <v-text-field
            hide-details
            v-on:focus="$event.target.select()"
            outlined
            dense
            v-model="title_th"
            placeholder="ใส่ลิ้ง URL"
            ref="textField"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="5" sm="5" xs="5"> </v-col>
      </v-row>
    </v-card>

    <!-- <v-divider class="mt-10"></v-divider> -->

    <v-row style="margin-top: 32px">
      <v-col cols="6" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
          >Banner</span
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="6" sm="6" class="text-right">
        <DialogBanners @getAllbanner="getAllbanner" />
        <DialogBannersEdit
          :method="method"
          :itemBannerEdit="itemBannerEdit"
          :dialogBannerEdit="dialogBannerEdit"
          @getAllbanner="getAllbanner"
          @closDialogBannerEdit="closDialogBannerEdit"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12" style="margin-top: -4px">
        <v-card flat
          ><v-data-table
            :headers="headersBrandTh"
            :items="items"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="ViewBanner(item)">mdi-content-copy</v-icon>
              <v-icon @click="UpdateBanner(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItemsBanner(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td v-if="isWindowWidthGreaterThan768">
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-start">{{ item.index }}</td>
                  <td class="text-start">
                    <v-img
                      :src="item.img_url"
                      width="120px"
                      class="ma-1"
                    ></v-img>
                  </td>
                  <td class="text-start">{{ item.title_th }}</td>
                  <!-- <td class="text-start">
                      <v-chip
                        :color="
                          item.status == 'เปิดใช้งาน'
                            ? '#ECFDF3'
                            : item.status == 'ฉบับร่าง'
                            ? '#F0F9FF'
                            : '#F2F4F7'
                        "
                        dark
                      >
                        <span
                          v-if="item.status == 'เปิดใช้งาน'"
                          style="color: #027a48"
                        >
                          {{ item.status }}
                        </span>
                        <span
                          v-if="item.status == 'ปิดใช้งาน'"
                          style="color: #344054"
                        >
                          {{ item.status }}
                        </span>
                        <span
                          v-if="item.status == 'ฉบับร่าง'"
                          style="color: #026aa2"
                        >
                          {{ item.status }}
                        </span>
                      </v-chip>
                    </td> -->

                  <td class="text-start">
                    <v-row justify="center">
                      <v-icon @click="ViewBanner(item)"
                        >mdi-content-copy</v-icon
                      >
                      <v-icon @click="UpdateBanner(item)" class="mx-2"
                        >mdi-pencil</v-icon
                      >
                      <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-divider class="mt-10"></v-divider> -->

    <v-row style="margin-top: 32px">
      <v-col cols="12" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
          >สถิติ</span
        >
      </v-col>
    </v-row>
    <v-card class="pa-6 mt-5">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <h2>จำนวนนักศึกษา</h2>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <span>นักศึกษาที่สำเร็จการศึกษา</span>
          <v-text-field
            hide-details
            v-model="form.graduated_amount"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <span>นักศึกษาปริญญาโท: </span>
          <v-text-field
            hide-details
            v-model="form.master_amount"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <span>นักศึกษาปริญญาเอก: </span>
          <v-text-field
            hide-details
            v-model="form.doctor_amount"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" md="6" sm="6"  >
          <v-btn
            style="float: right"
            color="#2AB3A3"
            @click="submitStudentAmount()"
            dark
            >บันทึก</v-btn
          >
        </v-col> -->
      </v-row>
    </v-card>

    <!-- <v-divider class="mt-10"></v-divider> -->

    <v-row style="margin-top: 32px">
      <v-col cols="12" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
          >ภาพบรรยากาศ</span
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" sm="6" class="text-right">
        <DialogGallery @getAllPicture="getAllPicture" />
        <DialogGalleryEdit
          :method="method"
          :itemPictureEdit="itemPictureEdit"
          :dialogPictureEdit="dialogPictureEdit"
          @getAllPicture="getAllPicture"
          @closDialogPictureEdit="closDialogPictureEdit"
        />
      </v-col>
    </v-row>
    <v-card class="mt-5"
      ><v-data-table
        :headers="headersPicture"
        :items="itemsPicture"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.img_url`]="{ item }">
          <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon @click="ViewNewsPicture(item)">mdi-content-copy</v-icon>
          <v-icon @click="UpdateNewsPicture(item)">mdi-pencil</v-icon>
          <v-icon @click="DeleteItemPicture(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
          <draggable
            :list="props.items"
            tag="tbody"
            @change="DragItemsPicture(props.items)"
          >
            <tr v-for="(item, index) in props.items" :key="index">
              <td v-if="isWindowWidthGreaterThan768">
                <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
              </td>
              <td class="text-start">{{ item.index }}</td>
              <td class="text-start">
                <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
              </td>
              <td class="text-start">{{ item.title_th }}</td>

              <td class="text-start">
                <v-row justify="center">
                  <v-icon @click="ViewNewsPicture(item)"
                    >mdi-content-copy</v-icon
                  >
                  <v-icon @click="UpdateNewsPicture(item)" class="mx-2"
                    >mdi-pencil</v-icon
                  >
                  <v-icon @click="DeleteItemPicture(item)">mdi-delete</v-icon>
                </v-row>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>

    <!-- <v-divider class="mt-10"></v-divider> -->

    <v-row style="margin-top: 32px">
      <v-col cols="12" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
          >ความเห็นจากนักศึกษา</span
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" sm="6" class="text-right">
        <!-- <v-btn @click="goToCreateComments()" color="#2AB3A3" dark
              >+ เพิ่มความเห็นจากศิษย์เก่า</v-btn
            > -->
        <DialogOldStudents @getAllStudentComment="getAllStudentComment" />
        <DialogOldStudentsEdit
          :method="method"
          :itemOldStudentEdit="itemOldStudentEdit"
          :dialogOldStudentEdit="dialogOldStudentEdit"
          @getAllStudentComment="getAllStudentComment"
          @closDialogOldStudentEdit="closDialogOldStudentEdit"
        />
      </v-col>
    </v-row>
    <v-card class="mt-5"
      ><v-data-table
        :headers="headersComments"
        :items="itemsComment"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
        style="margin-bottom: 80px"
      >
        <template v-slot:[`item.image_url`]="{ item }">
          <v-img :src="item.image_url" width="120px" class="ma-1"></v-img>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon @click="goToViewComments(item)">mdi-content-copy</v-icon>
          <v-icon @click="goToEditComments(item)">mdi-pencil</v-icon>
          <v-icon @click="DeleteItemComments(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
          <draggable
            :list="props.items"
            tag="tbody"
            @change="DragItemsComment(props.items)"
          >
            <tr v-for="(item, index) in props.items" :key="index">
              <td v-if="isWindowWidthGreaterThan768">
                <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
              </td>
              <td class="text-start">{{ item.index }}</td>
              <td class="text-start">
                <v-img :src="item.image_url" width="120px" class="ma-1"></v-img>
              </td>
              <td class="text-start">{{ item.comment_desc_th }}</td>
              <td class="text-start">{{ item.comment_from_th }}</td>

              <td class="text-start">
                <v-row justify="center">
                  <v-icon @click="goToViewComments(item)"
                    >mdi-content-copy</v-icon
                  >
                  <v-icon @click="goToEditComments(item)" class="mx-2"
                    >mdi-pencil</v-icon
                  >
                  <v-icon @click="DeleteItemComments(item)">mdi-delete</v-icon>
                </v-row>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import DialogBanners from "../Dialogs/DialogBanners.vue";
import DialogBannersEdit from "../Dialogs/DialogBannersEdit.vue";
import DialogGallery from "../Dialogs/DialogGallery.vue";
import DialogGalleryEdit from "../Dialogs/DialogGalleryEdit.vue";
import DialogOldStudents from "../Dialogs/DialogOldStudents.vue";
import DialogOldStudentsEdit from "../Dialogs/DialogOldStudentsEdit.vue";

export default {
  components: {
    draggable,
    Loading,
    DialogBanners,
    DialogBannersEdit,
    DialogGallery,
    DialogGalleryEdit,
    DialogOldStudents,
    DialogOldStudentsEdit,
  },
  data() {
    return {
      method: "",
      dialogBannerEdit: false,
      dialogPictureEdit: false,
      dialogOldStudentEdit: false,
      itemBannerEdit: {},
      itemPictureEdit: {},
      itemOldStudentEdit: {},
      title_en: null,
      title_th: null,
      desc_en: null,
      desc_th: null,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      file: [],
      loading: false,

      comment_desc: "",
      comment_from: "",
      comment_position: "",
      form: {
        graduated_amount: "",
        master_amount: "",
        doctor_amount: "",
      },
      headersPicture: [],
      headersPicture1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ชื่อภาพ(ไทย)", value: "title_th", align: "start" },
        // { text: "คำอธิบาย(ไทย)", value: "desc_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersPicture2: [
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ชื่อภาพ(ไทย)", value: "title_th", align: "start" },
        // { text: "คำอธิบาย(ไทย)", value: "desc_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersComments: [],
      headersComments1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูป", value: "image_url", align: "start" },
        {
          text: "รายละเอียด(ไทย)",
          value: "comment_desc_th",
          align: "start",
          width: "500px",
        },
        { text: "คอมเม้นโดย(ไทย)", value: "comment_from_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersComments2: [
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูป", value: "image_url", align: "start" },
        {
          text: "รายละเอียด(ไทย)",
          value: "comment_desc_th",
          align: "start",
          width: "500px",
        },
        { text: "คอมเม้นโดย(ไทย)", value: "comment_from_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrandTh: [],
      headersBrandTh1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ชื่อ Banner(TH)", value: "title_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrandTh2: [
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ชื่อ Banner(TH)", value: "title_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      items: [],
      itemsComment: [],
      itemsPicture: [],
      search: "",
      index: 0,
      user: "",
      loading: false,
      imgUrl: "",
      imgUrl1: "",

      window: {
        width: 0,
        height: 0,
      },
    };
  },

  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAllbanner();
    this.getDataStudentAmount();
    this.getAllStudentComment();
    this.getAllPicture();
    this.getAllWelcomePopUp();
    document.title = "GSCM Management System";

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  mounted() {
    if (this.isWindowWidthGreaterThan768) {
      this.headersBrandTh = this.headersBrandTh1;
      this.headersPicture = this.headersPicture1;
      this.headersComments = this.headersComments1;
    } else {
      this.headersBrandTh = this.headersBrandTh2;
      this.headersPicture = this.headersPicture2;
      this.headersComments = this.headersComments2;
    }
  },

  // destroyed() {
  //   window.removeEventListener("resize", this.handleResize);
  // },

  methods: {
    focusTextField() {
      this.$nextTick(() => {
        this.$refs.textField.focus(); // Use $refs to access the text field element and call the focus method
      });
    },
    scrollToTop() {
      console.log("test");
      window.scrollTo(0, 100);
    },
    deleteWelcomePopUp() {
      this.imgUrl1 = "";
      this.showImage = "";
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.imgUrl1 = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },

    async submitAll(q_1_1) {
      await this.submitStudentAmount();
      await this.submitWelcomePopUp(q_1_1);
      setTimeout(() => this.$router.go(0), 1000);
    },

    async submitWelcomePopUp(pic) {
      await this.submitfileMain(pic);
      console.log("pic", pic);
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        title_th: this.title_th,
        // title_en: this.title_en,
        // desc_en: this.desc_en,
        // desc_th: this.desc_th,
        // desc: this.desc,
        img_url: this.imgUrl1,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/welcomePops/4`,
        data,
        auth
      );
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `บันทึกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async getAllWelcomePopUp() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/welcomePops`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllWelcomePopUp", response.data.data);
      this.imgUrl1 = response.data.data[0].img_url;
      this.showImage = response.data.data[0].img_url;
      this.title_th = response.data.data[0].title_th;
      this.loading = false;

      this.focusTextField();
    },

    async getAllStudentComment() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments?type=1`,
        auth
      );
      console.log("getAllStudentComment", response.data.data);
      this.itemsComment = response.data.data;

      if (this.itemsComment.length) {
        let miniText = "";
        for (let i in this.itemsComment) {
          miniText = this.itemsComment[i].comment_desc_th.substring(0, 500);
          this.itemsComment[i].comment_desc_th = miniText;
        }
      }

      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsComment) {
        this.itemsComment[i].index = parseInt(i) + 1;
      }
    },

    async getDataStudentAmount() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/c_configs/2`
      );
      console.log("response", response);
      this.form = response.data.data;
    },

    async submitStudentAmount() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/c_configs/2`,
        this.form,
        auth
      );
      console.log("response", response);
      // if (response.data.response_status == "SUCCESS") {
      //   this.$swal.fire({
      //     icon: "success",
      //     text: `บันทึกสำเร็จ`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   // await this.getData();
      // } else {
      //   this.$swal.fire({
      //     icon: "error",
      //     text: response.data.response_description,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
    },

    async DragItemsBanner(val) {
      this.loading = true;
      console.log("DragItemsBanner", this.items1);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }
      console.log("val", val);
      ///// updateItem /////
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/banners/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);
      ///// getItem /////
      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/banners`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items = response1.data.data;
      this.loading = false;
    },
    async DragItemsPicture(val) {
      this.loading = true;
      console.log("DragItemsPicture", this.itemsPicture);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/imageAtmospheres/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/imageAtmospheres`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.itemsPicture = response1.data.data;
      this.loading = false;
    },
    async DragItemsComment(val) {
      this.loading = true;
      console.log("DragItemsComment", this.itemsComment);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/studentComments/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments?type=1`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.itemsComment = response1.data.data;
      this.loading = false;
    },

    async getAllbanner() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banners`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },

    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/banners/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbanner();
        }
      });
    },

    async DeleteItemComments(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/studentComments/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllStudentComment();
        }
      });
    },

    goToCreate() {
      this.$router.push("CreateBanners");
    },

    goToCreateComments() {
      this.$router.push("CreateStudentComment");
    },

    async getAllPicture() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/imageAtmospheres`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.itemsPicture = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsPicture) {
        this.itemsPicture[i].index = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItemPicture(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/imageAtmospheres/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllPicture();
        }
      });
    },

    goToCreatePicture() {
      this.$router.push("CreatePicture");
    },

    async UpdateBanner(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banners/${val.id}`,
        auth
      );
      console.log("getOneBanner", response.data.data);
      this.itemBannerEdit = response.data.data;

      console.log(this.itemBannerEdit);
      this.method = "edit";
      this.dialogBannerEdit = true;
    },
    async ViewBanner(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banners/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      console.log("duplicateData", duplicateData);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/banners`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllbanner();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loading = false;
    },

    closDialogBannerEdit() {
      this.dialogBannerEdit = false;
    },

    async UpdateNewsPicture(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/imageAtmospheres/${val.id}`,
        auth
      );
      console.log("getOnePicture", response.data.data);
      this.itemPictureEdit = response.data.data;

      this.method = "edit";
      this.dialogPictureEdit = true;
    },
    async ViewNewsPicture(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/imageAtmospheres/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      console.log("duplicateData", duplicateData);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/imageAtmospheres`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllPicture();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.loading = false;
    },

    closDialogPictureEdit() {
      this.dialogPictureEdit = false;
    },

    async goToEditComments(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments/${val.id}`,
        auth
      );
      console.log("getOneStudentComment", response.data.data);
      this.itemOldStudentEdit = response.data.data;

      this.method = "edit";
      this.dialogOldStudentEdit = true;
    },
    async goToViewComments(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      console.log("duplicateData", duplicateData);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/studentComments`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllStudentComment();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.loading = false;
    },

    closDialogOldStudentEdit() {
      this.dialogOldStudentEdit = false;
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .hidden-button {
    display: none !important; /* Use !important to override other styles */
  }
}
</style>




