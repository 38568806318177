<template>
  <div class="text-right">
    <v-dialog v-model="dialog" width="700px" height="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มความเห็นจากศิษย์เก่า
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          เพิ่มความเห็นจากศิษย์เก่า

          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้รีวิว (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="comment_from_th"
                  dense
                  outlined
                  placeholder="ระบุชื่อศิษย์เก่า (TH)"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้รีวิว (EN)</span>
                <v-text-field
                  hide-details
                  v-model="comment_from_en"
                  dense
                  outlined
                  placeholder="ระบุชื่อศิษย์เก่า (EN)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ระดับการศึกษา</span>
                <v-autocomplete
                  hide-details
                  v-model="course_level"
                  placeholder="ระดับการศึกษา"
                  :items="levelItems"
                  item-value="id"
                  dense
                  outlined
                  @change="getMajor(course_level)"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name_th }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name_th }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">สาขา</span>
                <v-autocomplete
                  hide-details
                  v-model="majorId"
                  placeholder="สาขา"
                  :items="majorItems"
                  item-value="id"
                  dense
                  outlined
                  :disabled="course_level == !course_level ? true : false"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name_th }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name_th }}
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ตำแหน่งและที่ทำงานปัจจุบัน (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="comment_position_th"
                  dense
                  outlined
                  placeholder="ระบุตำแหน่งงาน (TH)"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ตำแหน่งและที่ทำงานปัจจุบัน (EN)</span>
                <v-text-field
                  hide-details
                  v-model="comment_position_en"
                  dense
                  outlined
                  placeholder="ระบุตำแหน่งงาน (EN)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รุ่นที่</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="generation_th"
                  placeholder="ระบุรุ่น"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field
              ></v-col>

              <v-col cols="12" md="12" sm="12">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รีวิว (TH)</span>
                <span style="color: red">*</span>
                <v-textarea
                  hide-details
                  v-model="comment_desc_th"
                  dense
                  outlined
                  auto-grow
                  counter="140"
                  placeholder="ระบุความเห็นจากศิษย์เก่า (TH)"
                  :rules="rules.required"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รีวิว (EN)</span>
                <v-textarea
                  hide-details
                  v-model="comment_desc_en"
                  dense
                  outlined
                  auto-grow
                  counter="140"
                  placeholder="ระบุความเห็นจากศิษย์เก่า (EN)"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-col cols="12" md="12" sm="12" class="ml-n3"
              ><span class="pt-2">รูปศิษย์เก่า</span>
              <span style="color: red">*</span>
            </v-col>
            <v-row>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage">
                <v-img
                  :src="showImage"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage">
                <v-img
                  src="../../assets/noFile.png"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <span>{{ namePic }}</span>
                <br />
                <span>ขนาดที่แนะนำ 500x250px</span>
                <br />
                <br />
                <br />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="file"
                  ref="q_1_1"
                  v-on:change="submitfileMain('q_1_1')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="dialog = false"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      namePic: "",
      dialog: false,
      course_level: [],
      majorId: [],
      comment_desc_en: "",
      comment_desc_th: "",
      comment_from_th: "",
      comment_from_en: "",
      comment_position_th: "",
      comment_position_en: "",
      generation_th: "",
      generation_en: "",
      img_url: "",

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,

      file: [],
      levelItems: [],
      majorItems: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.getLevel();
  },
  methods: {
    async getLevel() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/levels`
      );
      this.levelItems = response.data.data;
      console.log("levelItems", this.levelItems);
    },
    async getMajor(levelId) {
      this.majorItems = [];
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/majors?levelId=${levelId}`
      );
      this.majorItems = response.data.data;
      console.log("majorItems", this.majorItems);
    },
    cancel() {
      this.$refs.createForm.reset();
      this.dialog = false;
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        this.namePic = this.$refs[questionNo].files[0].name;
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },
    async submit(pic) {
      if (this.$refs.createForm.validate(true)) {
        // await this.submitfileMain(pic);
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          levelId: this.course_level,
          majorId: this.majorId,
          comment_desc_en: this.comment_desc_en,
          comment_desc_th: this.comment_desc_th,
          comment_from_th: this.comment_from_th,
          comment_from_en: this.comment_from_en,
          comment_position_th: this.comment_position_th,
          comment_position_en: this.comment_position_en,
          generation_th: this.generation_th,
          generation_en: this.generation_en,
          image_url: this.img_url,
          type: 1,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/studentComments`,
          data,
          // this.form,
          auth
        );
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs.createForm.reset();
          this.$refs.q_1_1.value = "";
          this.showImage = "";
          this.$emit("getAllStudentComment");
          this.dialog = false;
          // this.$router.push("ManageBanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>