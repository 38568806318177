<template>
  <div class="text-right">
    <v-dialog
      v-model="dialogPictureEdit"
      width="700px"
      height="auto"
      @click:outside="cancel()"
      @keydown.esc="cancel()"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มแบนเนอร์
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5">
          แก้ไขแบนเนอร์
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อภาพบรรยากาศ (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="title_th"
                  placeholder="ระบุชื่อภาพบรรยากาศ (TH)"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อภาพบรรยากาศ (EN)</span>
                <v-text-field
                  hide-details
                  v-model="title_en"
                  placeholder="ระบุชื่อภาพบรรยากาศ (EN)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-col cols="12" md="12" sm="12" class="ml-n3"
              ><span class="pt-2">รูปบรรยากาศ</span>
              <span style="color: red">*</span>
            </v-col>

            <v-row>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage">
                <v-img
                  :src="showImage"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage">
                <v-img
                  src="../../assets/noFile.png"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <span>{{ namePic }}</span>
                <br />
                <span>เลือกรูปภาพภาพบรรยากาศ</span>
                <br />
                <br />
                <br />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="file"
                  ref="q_1_1"
                  v-on:change="submitfileMain('q_1_1')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="cancel()"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  props: {
    dialogPictureEdit: Boolean,
    itemPictureEdit: Object,
    method: String,
  },
  data() {
    return {
      loading: false,
      namePic: "",
      // dialog: false,
      // createdAt: "2022-08-08T06:53:48.000Z"
      // desc: "b"
      // id: 2
      // img_url: "b"
      // sortindex: 1
      // title: "b"
      // updatedAt: "2022-08-08T06:53:48.000Z"

      title_en: null,
      title_th: null,
      desc_en: null,
      desc_th: null,
      img_url: null,
      // bannerDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],

      file: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
    dialogPictureEdit(visible) {
      if (visible) {
        console.log("this.method", this.method);
        console.log("this.itemPictureEdit", this.itemPictureEdit);
        this.title_th = this.itemPictureEdit.title_th;
        this.title_en = this.itemPictureEdit.title_en;
        this.desc_th = this.itemPictureEdit.desc_th;
        this.desc_en = this.itemPictureEdit.desc_en;
        this.img_url = this.itemPictureEdit.img_url;
        this.showImage = this.itemPictureEdit.img_url;
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {},
  methods: {
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        this.namePic = this.$refs[questionNo].files[0].name;
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        // await this.submitfileMain(pic);
        console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          title_th: this.title_th,
          title_en: this.title_en,
          desc_en: this.desc_en,
          desc_th: this.desc_th,
          img_url: this.img_url,
          desc: this.desc,
          // bannerUrl: this.bannerUrl,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/imageAtmospheres/${this.itemPictureEdit.id}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขแบนเนอร์ใหม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllPicture");
          this.cancel();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    // async showImg(val) {
    //   this.loading = true;
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/` + val
    //   );
    //   console.log("getAllproducts", response.data.data);
    //   this.img = response.data.data.imgUrl;
    //   this.loading = false;
    // },

    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$emit("closDialogPictureEdit");
    },
  },
};
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>